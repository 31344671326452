<template>
<section id="company">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1>
              <img src="../assets/bg/graphisoft_logo_white.png" />
          </h1>
          <div class="content" v-html="companyContent.text">
          </div>
          <router-link class="btn btn-secondary" :to="{name : 'Page_76'}">
            Bővebben
          </router-link>
          <div class="rslides_container">
            <div class="slideshow-container">
              <div class="mySlides fade">                        
                <img src="../assets/sliders/1.jpg" style="width:100%">
              </div>
          
              <div class="mySlides fade">
                <img src="../assets/sliders/2.jpg" style="width:100%">
              </div>
              
              <div class="mySlides fade">
                <img src="../assets/sliders/3.jpg" style="width:100%">
              </div>

              <div class="mySlides fade">
                <img src="../assets/sliders/4.jpg" style="width:100%">
              </div>

              <div class="mySlides fade">
                <img src="../assets/sliders/5.jpg" style="width:100%">
              </div>
              <div class="mySlides fade">
                <img src="../assets/sliders/6.jpg" style="width:100%">
              </div>
              <div class="mySlides fade">
                <img src="../assets/sliders/7.jpg" style="width:100%">
              </div>
              <a class="transparent-btns_nav prev"></a>
              <a class="transparent-btns_nav next"></a>
            </div>
            <br>
            <div class="dot-holder">
              <span class="dot" data-n="1"></span>
              <span class="dot" data-n="2"></span>
              <span class="dot" data-n="3"></span>
              <span class="dot" data-n="4"></span>
              <span class="dot" data-n="5"></span>
              <span class="dot" data-n="6"></span>
              <span class="dot" data-n="7"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default{
  name:'Company',
  props:['companyContent','pageId'],
  data(){
    let slideIndex = 0

    return{slideIndex}
  },  
  mounted(){
     this.listenerInit()
    this.changeSlides()
  },
  methods:{
    listenerInit(){
        let prev = document.querySelector('#company .prev')
        let next = document.querySelector('#company .next')
        prev.addEventListener('click', this.minusSlides.bind(this))
        next.addEventListener('click', this.plusSlides.bind(this))
        let dots = document.querySelectorAll('#company .dot');
        for(let i=0; i<dots.length; i++){
            dots[i].addEventListener('click', this.currentSlide.bind(this))
        }
    },
    plusSlides() {
        this.showSlides(this.slideIndex += 1)
    },
    minusSlides() {
        this.showSlides(this.slideIndex -= 1)
    },
    currentSlide(e) {
        let n = e.target.dataset.n;
        this.showSlides(this.slideIndex = n)
    },
    showSlides(n) {
        let i;
        let slides = document.getElementsByClassName("mySlides")
        let dots = document.getElementsByClassName("dot")
        if (n > slides.length) {this.slideIndex = 1}
        if (n < 1) {this.slideIndex = slides.length}
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(" active", "")
        }
        slides[this.slideIndex-1].style.display = "contents"
        dots[this.slideIndex-1].className += " active"
    },

    changeSlides() {
        let i;
        let slides = document.getElementsByClassName("mySlides")
        let dots = document.getElementsByClassName("dot")
        if(!dots){
          return
        }
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none"
        }
        this.slideIndex++;
        if (this.slideIndex > slides.length) {this.slideIndex = 1}    
        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(" active", "")
        }
        slides[this.slideIndex-1].style.display = "contents" 
        dots[this.slideIndex-1].className += " active"
        setTimeout(this.changeSlides.bind(this), 4000)
      }
  }
}
</script>

<style scoped>
#company {
  width: 100%;
  background: url('../assets/bg/company_bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
#company .container {
    padding: 90px 0 280px 0;
    position: relative;
}
#company .content {
    color: #fff;
    font-size: 20px;
    line-height: 24px;
    margin: 30px 0 30px 0;
}
#company .rslides_container {
    position: absolute;
    top: 480px;
    left: 0;
    right: 0;
}
#company .slideshow-container {
    position: relative;
    margin: auto;
}
#company .dot-holder {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 38px;
    text-align: center;
    z-index: 4;
}
/*--------------------
        SLIDERS
---------------------*/
/* Slideshow container */
#company .slideshow-container {
    position: relative;
    margin: auto;
}
/* Hide the images by default */
#company  .mySlides {
    display: none;
}
/* Next & previous buttons */
#company   .prev,#company  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}
/* Position the "next button" to the right */
#company  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
}
  /* On hover, add a black background color with a little bit see-through */
#company  .prev:hover,#company  .next:hover {
    background-color: rgba(0,0,0,0.8);
}  
#company  .dot-holder{
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 38px;
    text-align: center;
    z-index: 4;
}
  /* The dots/bullets/indicators */
#company  .dot {
    cursor: pointer;
    height: 10px;
    width: 10px;
    margin: 0 8px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}  
#company .active,#company .dot:hover {
    background-color: #FFFFFF;
}  
  /* Fading animation */
#company  .fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
}
@-webkit-keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
}
@keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
}
#company .rslides{position:relative;list-style:none;overflow:hidden;width:100%;padding:0;margin:0;}
#company .transparent-btns_nav{z-index:3;position:absolute;-webkit-tap-highlight-color:rgba(0,0,0,0);top:0;left:0;display:block;background:#fff; /* Fix for IE6-9 */opacity:0;filter:alpha(opacity=1);width:48%;text-indent:-9999px;overflow:hidden;height:100%;}
#company .transparent-btns_nav.next{left:auto;right:0;}
#company .transparent-btns_nav:focus{outline:none;}
@media (max-width: 1030px) {
   #company .container{padding:60px 0 60px 0;}
    #company .rslides_container{position:relative;top:0;margin:30px 0 0 0;}
}
@media (max-width: 768px) {
  #company .btn-secondary{display: inline;}
  #company img.header-img{max-width:100%;}
  #company h1{background:none;}
}
@media screen and (max-width: 576px) {
  #company h1 img{
        width: 100%;
    }
}
</style>